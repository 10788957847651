<template>
 <div>
  <ExpertStatisticsTemplate
   :periodSelector="periodSelector"
   @timeSelector="selectedtime = $event"
   :elements="extensions"
   :clearTimeSelector="clearTimeSelector"
   @selector="
    $emit('update:selector', $event),
     $emit('setSelector', $event),
     $store.dispatch('pbxPeriodSelected', $event)
   "
   @setPeriod="(...args) => setPeriod(...args)"
   :startDate="startDate"
   :endDate="endDate"
   @activeBanner="$emit('activeBanner')"
   @successMsg="$emit('successMsg', $event)"
   @success="$emit('success', $event)"
   :urlType="urlType"
   @setLoading="$emit('setLoading', $event)">
   <template v-slot:selector>
    <PbxDashboardSelector
     :key="pbxElements"
     @selector="period = $event"
     @button-selector="(...args) => getSelectedButton(...args)"
     @action="
      $emit('action', $event),
       (selectedElement = $event),
       separateQueueExtensionData()
     "
     :selectHostname="selectHostname"
     :urlType="urlType"
     @queueName="selectedQueue = $event"
     :buttonSelected="buttonSelected"
     :elements="selectedElement"
     @timeSelector="selectedtime = $event"
     @concatenate="concatenateData = $event"
     :didNumbers="didNumbers" />
   </template>
   <template v-slot:content>
    <PbxHeader
     v-model:search="search"
     pbxMenuType="queueDetail"
     :periodSelector="period"
     :parseDateRange="parseDateRange"
     @select-hostname="selectHostname = $event"
     :selectedQueue="selectedQueue"
     :parseTimeRange="parseTimeRange"
     :timeSelected="selectedtime"
     :buttonSelected="buttonSelected"
     @activeBanner="(activeBanner = true), (key = !key)"
     @successMsg="successMsg = $event"
     @success="isSuccess = $event"
     :groupDatesQueueReport="groupData" />
    <div
     class="flex flex-col"
     v-if="queuesOnlyStats ? queueData.length > 0 : extensionData.length > 0">
     <div class="flex gap-2 justify-between py-2">
      <SwitchGroup as="div" class="flex items-center gap-1">
       <SwitchLabel as="span" class="flex gap-1 cursor-pointer">
        <span class="text-sm font-medium text-gray-900">{{
         $t("Afficher les extensions ")
        }}</span>
        <!-- <span class="text-sm text-gray-500">20s</span> -->
       </SwitchLabel>
       <Switch
        v-model="queuesOnlyStats"
        :class="[
         !queuesOnlyStats ? 'bg-teal-600' : 'bg-gray-200',
         'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
        ]">
        <span
         aria-hidden="true"
         :class="[
          !queuesOnlyStats ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
         ]" />
       </Switch>
       <SwitchLabel as="span" class="flex gap-1">
        <!-- <span class="text-sm font-medium text-gray-900">{{$t('displayAnswerTimeIn')}}</span> -->
        <!-- <span class="text-sm text-gray-500">30s</span> -->
       </SwitchLabel>
      </SwitchGroup>
      <!-- <SwitchGroup as="div" class="flex items-center gap-1">
      <SwitchLabel as="span" class="flex gap-1 cursor-pointer">
       <span class="text-sm font-medium text-gray-900">{{
        $t("groupData")
       }}</span>
      </SwitchLabel>
      <Switch
       v-model="groupData"
       :class="[
        groupData ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
       ]">
       <span
        aria-hidden="true"
        :class="[
         groupData ? 'translate-x-5' : 'translate-x-0',
         'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]" />
      </Switch>
      <SwitchLabel as="span" class="flex gap-1">
      </SwitchLabel>
     </SwitchGroup> -->
      <SwitchGroup as="div" class="flex items-center text-center gap-2">
       <Switch
        v-model="groupData"
        :class="[
         groupData ? 'bg-teal-600' : 'bg-teal-600',
         'relative flex gap-1 text-center h-7 w-60 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
        ]">
        <span
         v-if="!groupData"
         class="text-sm font-bold text-white capitalize px-2 py-0.5"
         >{{ $t("Période totale") }}</span
        >
        <!-- <span class="text-sm text-gray-500">20s</span> -->
        <span
         aria-hidden="true"
         :class="[
          !groupData ? 'translate-x-1 ' : 'translate-x-0 w-30',
          'pointer-events-none  h-6 text-center items-center px-4 py-0.5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
         ]">
         <SwitchLabel as="span" class="flex cursor-pointer">
          <span
           v-if="!groupData"
           class="text-sm font-bold text-gray-700 capitalize"
           >{{ $t("Jour par jour") }}</span
          >
          <span v-else class="text-sm font-bold text-gray-700 capitalize">{{
           $t("Période totale")
          }}</span>
          <!-- <span class="text-sm text-gray-500">20s</span> -->
         </SwitchLabel>
        </span>
        <span
         v-if="groupData"
         class="text-sm font-bold text-white capitalize px-2 py-0.5"
         >{{ $t("Jour par jour") }}</span
        >
        <!-- <span class="text-sm text-gray-500">20s</span> -->
       </Switch>
      </SwitchGroup>
     </div>
     <div class="overflow-hidden border-b border-gray-200 rounded-md">
      <FullReportTable
       :groupData="groupData"
       :currentSortDir="currentSortDir"
       :tableData="queuesOnlyStats ? filteredQueueData : filteredExtensionData"
       :header="queuesOnlyStats ? queueHeader : extensionHeader"
       :body="queuesOnlyStats ? queueDataKey : extensionDataKey"
       :selectedtime="selectedtime"
       :queuesArray="queuesArray"
       :queuesNameArray="queuesNameArray"
       :extensionsArray="extensionsArray"
       :extensionsNameArray="extensionsNameArray"
       :extensionsNameNumberArray="extensionsNameNumberArray"
       :queuesNameNumberArray="queuesNameNumberArray"
       v-model:searchQueue="searchQueue"
       v-model:searchQueueName="searchQueueName"
       v-model:searchExtension="searchExtension"
       v-model:searchExtensionName="searchExtensionName"
       :period="period"
       :parseDateRange="parseDateRange"
       :queuesOnlyStats="queuesOnlyStats"
       :timeRange="timeRange"
       :timeGap="timeGap"
       :numberOfColumns="numberOfColumns"
       :selectedRangeType="selectedRangeType"
       :concatenateData="concatenateData"
       @sort="sort($event)" />
     </div>
    </div>
    <div v-else class="mt-8 overflow-x-auto sm:-mx-6 lg:-mx-0">
     <div
      v-if="!this.pbxElements || this.pbxElements.length <= 0"
      class="text-center max-w-lg mx-auto space-y-4">
      <font-awesome-icon
       icon="chart-bar"
       class="mx-auto h-10 w-10 text-teal-600"
       aria-hidden="true" />
      <h1 class="text-gray-900 font-bold text-2xl">
       Analysez les appels entrants par numéro.
      </h1>
      <h3 class="mt-2 text-lg font-medium text-gray-900">
       Identifiez les files d'attente et les extensions les plus sollicitées
       pour chaque numéro. Analysez le taux d'appels répondus et la durée
       d'attente moyenne par numéro.
      </h3>
      <div class="pt-10">
       <PleaseSelectElement
        dataDescription="Veuillez sélectionner un numéro et une période" />
      </div>
     </div>
     <div
      v-else
      class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-6">
      <div class="overflow-hidden border-b border-gray-200 rounded-md">
       <NoData />
      </div>
     </div>
    </div>
    <!-- <div v-if="didNumbers.length <= 0" class="pt-10">
     <div class="text-center">
      <svg
       xmlns="http://www.w3.org/2000/svg"
       class="mx-auto h-12 w-12 text-gray-400"
       fill="none"
       viewBox="0 0 24 24"
       stroke="currentColor">
       <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M21 3l-6 6m0 0V4m0 5h5M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z" />
      </svg>
      <h3 class="mt-2 text-lg font-medium text-gray-900">
       {{ $t("Analysez les appels entrants par numéro") }}
      </h3>
     </div>
    </div> -->
   </template>
  </ExpertStatisticsTemplate>
 </div>
</template>

<script>
import NoData from "../../components/NoData.vue";
import PleaseSelectElement from "../../components/PleaseSelectElement.vue";
import ExpertStatisticsTemplate from "./ExpertStatisticsTemplate.vue";
import Tooltip from "../../components/Collect/formTools/Tooltip.vue";
import axios from "axios";
import { mapGetters } from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SuccessBanner from "../../components/SuccessBanner.vue";
import TablePagination from "../../components/TablePagination.vue";
import PbxHeader from "../../components/PBX/PbxHeader.vue";
import PbxDashboardSelector from "../../components/PBX/PbxDashboardSelector.vue";
import FullReportTable from "../../components/PBX/FullReportTable.vue";
import {
 Switch,
 SwitchGroup,
 SwitchLabel,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
} from "@headlessui/vue";
import { ChevronDownIcon, XIcon } from "@heroicons/vue/outline";
import {
 ArrowCircleRightIcon,
 ArrowCircleLeftIcon,
 InformationCircleIcon,
} from "@heroicons/vue/solid";

const durationMenuItems = [
 { name: "waiting", href: "#", text: "Durées d'attente" },
 //  { name: "talking", href: "#", text: "Durées d'appel" },
];

const keyType = {
 0: "extension",
 4: "queue",
};

const actionType = {
 answered: "answered",
 "1c": "answered_calls",
 transferred: "transferred",
 //  terminated_by_dst: "declined_solicitations",
 //  terminated_by_dst: "declined_calls",
 disuaded: "declined_solicitations",
 abandoned: "abandoned",
 ping: "ping",
 pong: "pong",
 calls: "calls",
 extension_pings: "extension_pings",
 solicited: "solicited",
};

const extensionHeader = [
 { text: "date", info: "", hasInfo: false },
 //  { text: "time", info: "", hasInfo: false },
 { text: "queue", info: "", hasInfo: false },
 { text: "extension", info: "", hasInfo: false },
 {
  text: "calls",
  info: "Nombre d’appels uniques reçus par la ressouce.",
  hasInfo: true,
  key: "calls",
 },
 {
  text: "solicited",
  info:
   "Nombre de sollications reçues par la ressource. Au cours d’un appel unique, la même ressource peut être sollicitée à plusieurs reprises.",
  hasInfo: true,
  key: "solicited",
 },
 {
  text: "declined",
  info:
   "Nombre de sollications rejetées par la ressource. Il s’agit d’une dissuasion dans le cas de la file d’appel et d’une non réponse dans le cas de l’extension.",
  hasInfo: true,
  key: "declined_solicitations",
 },
 {
  text: "abandoned",
  info: "Appels abandonnés par l’appelant.",
  hasInfo: true,
  key: "abandoned",
 },
 { text: "answered", info: "", hasInfo: false, key: "answered" },
 {
  text: "transferred",
  info:
   "Appels répondus, puis transférés vers une autre ressouce (file d’attente ou extension).",
  hasInfo: true,
  key: "transferred",
 },
 {
  text: "answered_percentage",
  info:
   "Ce pourcentage représente le rapport du nombre des appels répondus par la ressource au nombre total des appels présentés.",
  hasInfo: true,
  key: "answered_percentage",
 },
 { text: "talking_duration", info: "", hasInfo: false, key: "duration_total" },
 { text: "waiting_duration", info: "", hasInfo: false, key: "waiting_total" },
 {
  text: "ranges",
  info:
   "Nombre d’appels répondus dans le délai considéré. Seuls les appels effectivement répondus par la ressource sont pris en compte.",
  hasInfo: true,
 },
];

const queueHeader = [
 { text: "date", info: "", hasInfo: false },
 //  { text: "time", info: "", hasInfo: false },
 { text: "queue", info: "", hasInfo: false },
 {
  text: "calls",
  info: "Nombre d’appels uniques reçus par la ressouce.",
  hasInfo: true,
  key: "calls",
 },
 //  {
 //   text: "solicited",
 //   info:
 //    "Nombre de sollications reçues par la ressource. Au cours d’un appel unique, la même ressource peut être sollicitée à plusieurs reprises.",
 //   hasInfo: true,
 //   key: "solicited",
 //  },
 {
  text: "declined",
  info:
   "Nombre de sollications rejetées par la ressource. Il s’agit d’une dissuasion dans le cas de la file d’appel et d’une non réponse dans le cas de l’extension.",
  hasInfo: true,
  key: "declined_solicitations",
 },
 {
  text: "abandoned",
  info: "Appels abandonnés par l’appelant.",
  hasInfo: true,
  key: "abandoned",
 },
 {
  text: "answered",
  info: "",
  hasInfo: false,
  key: "answered",
 },
 {
  text: "transferred",
  info:
   "Appels répondus, puis transférés vers une autre ressouce (file d’attente ou extension).",
  hasInfo: true,
  key: "transferred",
 },
 {
  text: "answered_percentage",
  info:
   "Ce pourcentage représente le rapport du nombre des appels répondus par la ressource au nombre total des appels présentés.",
  hasInfo: true,
  key: "answered_percentage",
 },
 { text: "talking_duration", info: "", hasInfo: false, key: "duration_total" },
 { text: "waiting_duration", info: "", hasInfo: false, key: "waiting_total" },
 {
  text: "ranges",
  info:
   "Nombre d’appels répondus dans le délai considéré. Seuls les appels effectivement répondus par la ressource sont pris en compte.",
  hasInfo: true,
 },
];

const extensionDataKey = [
 "formatedDate",
 "queueNameNumber",
 "elementNameNumber",
 "calls_ext",
 "solicited",
 "declined_solicitations",
 "abandoned",
 "answered",
 "transferred",
 "answered_percentage_ext",
 "talking_duration",
 "waiting_duration",
 "ranges",
];

const queueDataKey = [
 "formatedDate",
 "queueNameNumber",
 "calls",
 //  "solicited",
 "declined_solicitations",
 "abandoned",
 "answered",
 "transferred",
 "answered_percentage_ext",
 "talking_duration",
 "waiting_duration",
 "ranges",
];

export default {
 props: ["urlType"],
 components: {
  Loading,
  PbxHeader,
  PbxDashboardSelector,
  TablePagination,
  SuccessBanner,
  FullReportTable,
  Switch,
  SwitchGroup,
  SwitchLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  ChevronDownIcon,
  Tooltip,
  ArrowCircleRightIcon,
  ArrowCircleLeftIcon,
  InformationCircleIcon,
  XIcon,
  ExpertStatisticsTemplate,
  PleaseSelectElement,
  NoData,
 },
 data() {
  return {
   currentSortDir: "asc",
   currentSort: "",
   showInfoText: false,
   durationMenuItems,
   selectedRangeType: "waiting",
   selectedRangeTypeText: "Durées d'attente",
   timeRange: 20,
   timeGap: 10,
   numberOfColumns: 4,
   groupData: true,
   searchQueue: null,
   searchQueueName: null,
   searchExtension: null,
   searchExtensionName: null,
   queuesNameArray: [],
   queuesArray: [],
   extensionsNameArray: [],
   extensionsNameNumberArray: [],
   queuesNameNumberArray: [],
   extensionsArray: [],
   queueHeader,
   queueDataKey,
   extensionHeader,
   extensionDataKey,
   queuesOnlyStats: false,
   keyType,
   actionType,
   data: {},
   selectedtime: undefined,
   fullPage: true,
   selectedElement: "",
   selectedQueue: "",
   period: "",
   selectHostname: "",
   queueData: [],
   extensionData: [],
   textMonth: "",
   buttonSelected: "month",
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   textMonth: "",
   buttonSelected: "month",
   settedGroupTableData: [],
   settedUngroupedTableData: [],
   didNumbers: [],
   concatenateData: false,
   startDate: null,
   endDate: null,
  };
 },
 methods: {
  setPeriod(...args) {
   this.startDate = args[0];
   this.endDate = args[1];

   let flag = false;
   if (this.concatenateData) {
    flag = true;
    this.concatenateData = false;
   }
   this.separateQueueExtensionData();
   if (flag) this.concatenateData = true;
  },
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  async getData(sentPeriod) {
   this.$emit("setLoading", true);

   //    let arrayOfElements = [];
   //    let stringOfElements = "";
   //    this.pbxElements.forEach((element, index) => {
   //     let elementValue = element;
   //     if (typeof elementValue === "object") {
   //      for (let index = 0; index < elementValue.length; index++) {
   //       const element = elementValue[index];
   //       arrayOfElements.push(element);
   //      }
   //     } else {
   //      arrayOfElements.push(element);
   //     }
   //    });

   //    arrayOfElements = [...new Set(arrayOfElements)];

   //    stringOfElements = String(arrayOfElements[0]);

   //    for (let index = 1; index < arrayOfElements.length; index++) {
   //     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
   //    }
   if (
    this.pbxElements &&
    this.pbxElements.length > 0 &&
    this.startDate &&
    this.endDate
   ) {
    let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
     this.hostName
    }/dids-report?start=${this.startDate}&end=${this.endDate}&dids=${
     this.selectedElement
    }`;
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     });
     
     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  async setTableData(data) {
   if (this.pbxElements && this.pbxElements.length > 0) {
    let rawDataArray = [];
    let dataArray = [];
    let dataObj = {};
    let queueReportData = data;
    let queueReportDataUngrouped = queueReportData["daily"];
    if (this.selectedtime && this.selectedtime.length > 1) {
     queueReportDataUngrouped = queueReportData["all"];
    }

    let pbxMapData =
     this.pbxMap && Object.keys(this.pbxMap).length > 0
      ? this.pbxMap
      : this.getPbxMap();

    let queueDataKeysUngrouped = Object.keys(queueReportDataUngrouped);
    queueDataKeysUngrouped.forEach((key, index) => {
     let splitedKey = key.split(":");
     let queueNumber = splitedKey[2];
     let typeInfo = splitedKey[3];
     let elementNumber = splitedKey[4];
     let dataPeriod =
      splitedKey[0].length == 12 ? splitedKey[0] : splitedKey[0].slice(0, 8);
     let dataTime = splitedKey[0].length == 12 ? splitedKey[0].slice(-4) : null;
     let dataAction = splitedKey[5];
     let actionType =
      dataAction === "actions"
       ? this.actionType[splitedKey[6]]
       : dataAction === "duration" && !splitedKey[6].includes("_")
       ? [splitedKey[6]] + "_" + "duration"
       : dataAction === "duration" && splitedKey[6].includes("_")
       ? [splitedKey[6]]
       : null;
     dataObj = {};
     dataObj["queue"] = queueNumber;
     dataObj["queueName"] =
      pbxMapData &&
      pbxMapData["call_queues"] &&
      pbxMapData["call_queues"][queueNumber]
       ? pbxMapData["call_queues"][queueNumber]["name"]
       : null;
     dataObj["element"] = elementNumber;
     dataObj["elementName"] =
      typeInfo == 0 &&
      pbxMapData &&
      pbxMapData["extensions"] &&
      pbxMapData["extensions"][elementNumber]
       ? pbxMapData["extensions"][elementNumber]
       : null;
     dataObj["queueNameNumber"] =
      dataObj["queue"] +
      "-" +
      (dataObj["queueName"] ? dataObj["queueName"] : "");
     dataObj["elementNameNumber"] =
      dataObj["element"] +
      "-" +
      (dataObj["elementName"] ? dataObj["elementName"] : "");
     dataObj["date"] = dataPeriod;
     dataObj["time"] = dataTime;
     dataObj["formatedDate"] = this.parseDateRange(dataPeriod.slice(0, 8));
     dataObj["keyType"] = typeInfo;
     let action =
      dataAction === "actions" || dataAction === "duration"
       ? actionType
       : dataAction;
     dataObj[action] = queueReportDataUngrouped[key];
     dataObj[`${action}`] =
      dataAction === "actions"
       ? queueReportDataUngrouped[key]
       : queueReportDataUngrouped[key];
     if (this.selectedtime && this.selectedtime.length > 1) {
      let firsQuarterHour =
       (
        "0" +
        (parseInt(this.selectedtime[0].slice(0, 2)) +
         parseInt(this.hostTimeZone))
       ).slice(-2) + this.selectedtime[0].slice(-2);
      let lastQuarterHour =
       (
        "0" +
        (parseInt(this.selectedtime[1].slice(0, 2)) +
         parseInt(this.hostTimeZone))
       ).slice(-2) + this.selectedtime[1].slice(-2);
      if (dataTime) {
       if (
        parseInt(firsQuarterHour) <= parseInt(dataTime) &&
        parseInt(dataTime) <= parseInt(lastQuarterHour)
       ) {
        rawDataArray.push(dataObj);
       }
      }
     } else {
      if (
       action &&
       dataPeriod == splitedKey[0].slice(0, 8) &&
       !splitedKey[0].includes("w")
      ) {
       rawDataArray.push(dataObj);
      }
     }
    });
    let elements = [];
    let elementsName = [];
    let elementsNameNumber = [];
    let dates = [];
    let queues = [];
    let queuesName = [];
    let queuesNameNumber = [];
    rawDataArray.forEach(el => {
     if (el.element) {
      elements.push(el.element);
     }
     if (el.date) {
      dates.push(el.date);
     }
     if (el.queue) {
      queues.push(el.queue);
     }
     if (el.queueName) {
      queuesName.push(el.queueName);
     }
     if (el.elementName && el.keyType == 0) {
      elementsName.push(el.elementName);
     }
     if (el.element && el.elementNameNumber && el.keyType == 0) {
      elementsNameNumber.push(el.elementNameNumber);
     }
     if (el.queueNameNumber) {
      queuesNameNumber.push(el.queueNameNumber);
     }
    });
    let uniqElements = [...new Set(elements)];
    let uniqElementsName = [...new Set(elementsName)];
    let uniqElementsNameNumber = [...new Set(elementsNameNumber)];
    let uniqDates = [...new Set(dates)];
    let uniqQueues = [...new Set(queues)];
    let uniqQueuesName = [...new Set(queuesName)];
    let uniqQueuesNameNumber = [...new Set(queuesNameNumber)];

    this.queuesArray = uniqQueues;
    this.queuesNameArray = uniqQueuesName;
    this.queuesNameNumberArray = uniqQueuesNameNumber;
    this.extensionsArray = uniqElements;
    this.extensionsNameArray = uniqElementsName;
    this.extensionsNameNumberArray = uniqElementsNameNumber;

    uniqElements.forEach(el => {
     let filteredData = rawDataArray.filter(res => {
      return el == res.element;
     });

     uniqDates.forEach(d => {
      let filteredData2 = filteredData.filter(res => {
       return d == res.date;
      });
      uniqQueues.forEach(queue => {
       let filteredData3 = filteredData2.filter(res => {
        return queue == res.queue;
       });
       let mergedObjs = {};
       if (filteredData3.length > 0) {
        for (let index = 0; index < filteredData3.length; index++) {
         const element = filteredData3[index];
         mergedObjs = { ...mergedObjs, ...element };
        }
        dataArray.push(mergedObjs);
       }
      });
     });
    });

    //  let sortedDataArray = dataArray.sort((a, b) => {
    //   return parseInt(a.date) - parseInt(b.date);
    //  });
    //  this.$emit("setLoading", false);
    return dataArray;
   }
  },
  async setTableDataGrouped(data) {
   if (this.pbxElements && this.pbxElements.length > 0) {
    this.$emit("setLoading", true);
    let rawDataArray = [];
    let dataArray = [];
    let dataObj = {};
    let queueReportData = data;
    let queueReportDataGrouped = queueReportData["daily"];
    if (this.selectedtime && this.selectedtime.length > 1) {
     queueReportDataGrouped = queueReportData["all"];
    }

    let queueDataKeysGrouped = Object.keys(queueReportDataGrouped);

    let pbxMapData =
     this.pbxMap && Object.keys(this.pbxMap).length > 0
      ? this.pbxMap
      : this.getPbxMap();

    queueDataKeysGrouped.forEach((key, index) => {
     let splitedKey = key.split(":");
     let queueNumber = splitedKey[2];
     let typeInfo = splitedKey[3];
     let elementNumber = splitedKey[4];
     let dataTime = splitedKey[0].length == 12 ? splitedKey[0].slice(-4) : null;
     let dataAction = splitedKey[5];
     let actionType =
      dataAction === "actions"
       ? this.actionType[splitedKey[6]]
       : dataAction === "duration" && !splitedKey[6].includes("_")
       ? [splitedKey[6]] + "_" + "duration"
       : dataAction === "duration" && splitedKey[6].includes("_")
       ? [splitedKey[6]]
       : null;
     dataObj = {};
     dataObj["queue"] = queueNumber;
     dataObj["queueName"] =
      pbxMapData &&
      pbxMapData["call_queues"] &&
      pbxMapData["call_queues"][queueNumber]
       ? pbxMapData["call_queues"][queueNumber]["name"]
       : null;
     dataObj["element"] = elementNumber;
     dataObj["elementName"] =
      typeInfo == 0 &&
      pbxMapData &&
      pbxMapData["extensions"] &&
      pbxMapData["extensions"][elementNumber]
       ? pbxMapData["extensions"][elementNumber]
       : null;
     dataObj["queueNameNumber"] =
      dataObj["queue"] +
      "-" +
      (dataObj["queueName"] ? dataObj["queueName"] : "");
     dataObj["elementNameNumber"] =
      dataObj["element"] +
      "-" +
      (dataObj["elementName"] ? dataObj["elementName"] : "");
     dataObj["time"] = dataTime;
     dataObj["keyType"] = typeInfo;
     let action =
      dataAction === "actions" || dataAction === "duration"
       ? actionType
       : dataAction;
     dataObj[action] = queueReportDataGrouped[key];
     dataObj[`${action}`] =
      dataAction >= 0
       ? queueReportDataGrouped[key]
       : queueReportDataGrouped[key];
     if (this.selectedtime && this.selectedtime.length > 1) {
      let firsQuarterHour =
       (
        "0" +
        (parseInt(this.selectedtime[0].slice(0, 2)) +
         parseInt(this.hostTimeZone))
       ).slice(-2) + this.selectedtime[0].slice(-2);
      let lastQuarterHour =
       (
        "0" +
        (parseInt(this.selectedtime[1].slice(0, 2)) +
         parseInt(this.hostTimeZone))
       ).slice(-2) + this.selectedtime[1].slice(-2);
      if (dataTime) {
       if (
        parseInt(firsQuarterHour) <= parseInt(dataTime) &&
        parseInt(dataTime) <= parseInt(lastQuarterHour)
       ) {
        rawDataArray.push(dataObj);
       }
      }
     } else {
      if (action && splitedKey[0].length == 8) {
       rawDataArray.push(dataObj);
      }
     }
    });
    let elements = [];
    let elementsName = [];
    let elementsNameNumber = [];
    let dates = [];
    let queues = [];
    let queuesName = [];
    let queuesNameNumber = [];
    rawDataArray.forEach(el => {
     if (el.element) {
      elements.push(el.element);
     }
     if (el.date) {
      dates.push(el.date);
     }
     if (el.queue) {
      queues.push(el.queue);
     }
     if (el.queueName) {
      queuesName.push(el.queueName);
     }
     if (el.elementName && el.keyType == 0) {
      elementsName.push(el.elementName);
     }
     if (el.element && el.elementNameNumber && el.keyType == 0) {
      elementsNameNumber.push(el.elementNameNumber);
     }
     if (el.queueNameNumber) {
      queuesNameNumber.push(el.queueNameNumber);
     }
    });
    let uniqElements = [...new Set(elements)];
    let uniqElementsName = [...new Set(elementsName)];
    let uniqElementsNameNumber = [...new Set(elementsNameNumber)];
    let uniqQueues = [...new Set(queues)];
    let uniqQueuesName = [...new Set(queuesName)];
    let uniqQueuesNameNumber = [...new Set(queuesNameNumber)];

    this.queuesArray = uniqQueues;
    this.queuesNameArray = uniqQueuesName;
    this.queuesNameNumberArray = uniqQueuesNameNumber;
    this.extensionsArray = uniqElements;
    this.extensionsNameArray = uniqElementsName;
    this.extensionsNameNumberArray = uniqElementsNameNumber;

    //     if (this.selectedtime && this.selectedtime.length > 1) {
    for (let index = 0; index < uniqElements.length; index++) {
     const uniqElement = uniqElements[index];

     for (let index = 0; index < uniqQueues.length; index++) {
      let obj = {};
      obj["waiting_duration"] = 0;
      obj["answering_duration"] = 0;
      obj["talking_duration"] = 0;
      obj["answered"] = 0;
      obj["declined_solicitations"] = 0;
      obj["transferred"] = 0;
      obj["abandoned"] = 0;
      obj["calls"] = 0;
      obj["solicited"] = 0;
      obj["dissuaded"] = 0;
      const uniqQueue = uniqQueues[index];

      rawDataArray.forEach(el => {
       let elKeys = Object.keys(el);
       if (el.element == uniqElement && el.queue == uniqQueue) {
        elKeys.forEach(key => {
         if (
          (key.includes("answering") || key.includes("talking")) &&
          !key.includes("duration")
         ) {
          obj[key] = obj[key] ? parseInt(obj[key]) : 0;
          obj[key] = parseInt(obj[key]) + parseInt(el[key]);
         }
        });
        obj["element"] = el.element;
        obj["elementName"] = el.elementName;
        obj["elementNameNumber"] = el.elementNameNumber;
        obj["queue"] = el.queue;
        obj["queueName"] = el.queueName;
        obj["queueNameNumber"] = el.queueNameNumber;
        obj["keyType"] = el.keyType;
        obj["waiting_duration"] =
         obj["waiting_duration"] +
         (el.waiting_duration ? parseInt(el.waiting_duration) : 0);
        obj["answering_duration"] =
         obj["answering_duration"] +
         (el.answering_duration ? parseInt(el.answering_duration) : 0);
        obj["talking_duration"] =
         obj["talking_duration"] +
         (el.talking_duration ? parseInt(el.talking_duration) : 0);
        obj["answered"] =
         obj["answered"] + (el.answered ? parseInt(el.answered) : 0);
        obj["declined_solicitations"] =
         obj["declined_solicitations"] +
         (el.declined_solicitations ? parseInt(el.declined_solicitations) : 0);
        obj["transferred"] =
         obj["transferred"] + (el.transferred ? parseInt(el.transferred) : 0);
        obj["abandoned"] =
         obj["abandoned"] + (el.abandoned ? parseInt(el.abandoned) : 0);
        obj["calls"] = obj["calls"] + (el.calls ? parseInt(el.calls) : 0);
        obj["solicited"] =
         obj["solicited"] + (el.solicited ? parseInt(el.solicited) : 0);
        obj["dissuaded"] =
         obj["dissuaded"] + (el.dissuaded ? parseInt(el.dissuaded) : 0);
       }
      });

      if (obj && obj.element && obj.queue) {
       let flag = true;
       for (let index = 0; index < dataArray.length; index++) {
        const elDataArray = dataArray[index];
        if (
         elDataArray.element === obj.element &&
         elDataArray.queue === obj.queue
        ) {
         flag = false;
        }
       }
       if (flag) {
        dataArray.push(obj);
       }
      }
     }
    }
    //     } else {
    //      uniqElements.forEach((el) => {
    //       let filteredData = rawDataArray.filter((res) => {
    //        return el == res.element;
    //       });

    //       uniqQueues.forEach((queue) => {
    //        let filteredData2 = filteredData.filter((res) => {
    //         return queue == res.queue;
    //        });
    //        let mergedObjs = {};
    //        if (filteredData2.length > 0) {
    //         for (let index = 0; index < filteredData2.length; index++) {
    //          const element = filteredData2[index];
    //          mergedObjs = { ...mergedObjs, ...element };
    //         }
    //         dataArray.push(mergedObjs);
    //        }
    //       });
    //      });
    //     }
    //  let sortedDataArray = dataArray.sort((a, b) => {
    //   return parseInt(a.element) - parseInt(b.element);
    //  });
    //  this.$emit("setLoading", false);
    return dataArray;
   }
  },
  async separateQueueExtensionData(groupSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   if (this.pbxElements && this.pbxElements.length > 0) {
    this.$emit("setLoading", true);
    this.queueData = [];
    this.extensionData = [];
    let dataArray = [];
    if (groupSelected && groupSelected === "grouped") {
     dataArray = this.groupData
      ? this.settedGroupTableData
      : this.settedUngroupTableData;
    } else {
     this.data = await this.getData();
     this.settedGroupTableData = await this.setTableDataGrouped(this.data);
     this.settedUngroupTableData = await this.setTableData(this.data);
     dataArray = this.groupData
      ? this.settedGroupTableData
      : this.settedUngroupTableData;
    }
    for (let index = 0; index < dataArray.length; index++) {
     const el = dataArray[index];
     if (el.queue == el.element && el.keyType == 4) {
      this.queueData.push(el);
     }
     if (el.queue == el.element || el.keyType == 0) {
      this.extensionData.push(el);
     }
    }
    if (this.pbxMap && this.pbxMap["dids"]) {
     this.selectedElement = this.selectedElement.map(res => {
      return (
       res + "-" + (this.pbxMap["dids"][res] ? this.pbxMap["dids"][res] : "")
      );
     });
    }
    this.consolidateDataQueue(this.queueData);
    this.consolidateDataExtension(this.extensionData);
   } else {
    this.queueData = [];
    this.extensionData = [];
   }
   this.$emit("setLoading", false);
  },
  consolidateDataQueue(data) {
   let consolidatedData = [];
   let holder = {};
   let ignoreKeys = [
    "element",
    "elementName",
    "elementNameNumber",
    "keyType",
    "queue",
    "queueName",
    "queueNameNumber",
   ];
   if (data && data.length > 0) {
    data.forEach(res => {
     let keys = Object.keys(res);
     for (let index = 0; index < keys.length; index++) {
      const elementProperty = keys[index];
      if (ignoreKeys.indexOf(elementProperty) < 0) {
       if (holder.hasOwnProperty(elementProperty)) {
        holder[elementProperty] =
         parseInt(holder[elementProperty]) + parseInt(res[elementProperty]);
       } else {
        holder[elementProperty] = parseInt(res[elementProperty]);
       }
      }
     }
    });
   }
   consolidatedData.unshift(holder);
   holder["queueNameNumber"] = "Consolidée";
   this.queueDataConsolidated = consolidatedData;
  },
  consolidateDataExtension(data) {
   let duplicatedArr = [];
   let extensions = [];
   data.forEach(res => {
    if (res.keyType == 0) {
     extensions.push(res.element);
    }
   });
   let duplicatedElements = extensions.filter(
    (item, index) => extensions.indexOf(item) !== index
   );

   let consolidatedData = [];
   let holder = {};
   let extensionHolder = {};
   let ignoreKeys = [
    "element",
    "elementName",
    "elementNameNumber",
    "keyType",
    "queue",
    "queueName",
    "queueNameNumber",
   ];
   if (data && data.length > 0) {
    data.forEach((res, resIndex) => {
     let keys = Object.keys(res);
     if (res.keyType == 4) {
      holder["queueNameNumber"] = "Consolidée";
      holder["keyType"] = 4;
      for (let index = 0; index < keys.length; index++) {
       const elementProperty = keys[index];
       if (ignoreKeys.indexOf(elementProperty) < 0) {
        if (holder.hasOwnProperty(elementProperty)) {
         holder[elementProperty] =
          parseInt(holder[elementProperty]) + parseInt(res[elementProperty]);
        } else {
         holder[elementProperty] = parseInt(res[elementProperty]);
        }
       }
      }
      if (
       consolidatedData[0] &&
       consolidatedData[0]["keyType"] &&
       consolidatedData[0]["keyType"] != 4
      )
       consolidatedData.unshift(holder);
     } else {
      if (duplicatedElements.indexOf(res.element) < 0) {
       consolidatedData.push(res);
      } else {
       duplicatedArr.push(res);
      }
      //   extensionHolder = {};
      //   for (let index = 0; index < extensions.length; index++) {
      //    const elementExtension = extensions[index];
      //    if (elementExtension == res.element) {
      //     extensionHolder["queueNameNumber"] = "Consolidée";
      //     extensionHolder["elementNameNumber"] = res.elementNameNumber;
      //     extensionHolder["element"] = res.element;
      //     extensionHolder["keyType"] = res.keyType;
      //     for (let index = 0; index < keys.length; index++) {
      //      const elementProperty = keys[index];
      //      if (ignoreKeys.indexOf(elementProperty) < 0) {
      //       if (extensionHolder.hasOwnProperty(elementProperty)) {
      //        extensionHolder[elementProperty] = parseInt(extensionHolder[elementProperty]) + parseInt(res[elementProperty]);
      //       } else {
      //        extensionHolder[elementProperty] = parseInt(res[elementProperty]);
      //       }
      //      }
      //     }
      //     consolidatedData[index + 1] = extensionHolder;
      //    }
      //   }
     }
    });
    let arr = [];
    let uniqueDuplicatedElements = [...new Set(duplicatedElements)];
    for (
     let elementsIndex = 0;
     elementsIndex < uniqueDuplicatedElements.length;
     elementsIndex++
    ) {
     let obj = {};
     const element = uniqueDuplicatedElements[elementsIndex];
     duplicatedArr.forEach((dup, dupIdx) => {
      let keys = Object.keys(dup);
      if (element == dup.element) {
       obj["queueNameNumber"] = "Consolidée";
       obj["elementNameNumber"] = dup.elementNameNumber;
       obj["element"] = dup.element;
       obj["keyType"] = dup.keyType;
       obj["elementName"] = dup.elementName;
       for (let index = 0; index < keys.length; index++) {
        const elementProperty = keys[index];
        if (ignoreKeys.indexOf(elementProperty) < 0) {
         if (obj.hasOwnProperty(elementProperty)) {
          obj[elementProperty] =
           parseInt(obj[elementProperty]) + parseInt(dup[elementProperty]);
         } else {
          obj[elementProperty] = parseInt(dup[elementProperty]);
         }
        }
       }
      }
     });
     arr[elementsIndex] = obj;
    }
    consolidatedData = [...arr, ...consolidatedData];
   }
   this.extensionDataConsolidated = consolidatedData;
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedDataExtension() {
   let extensionData =
    this.concatenateData && this.groupData
     ? this.extensionDataConsolidated.filter(res => res.keyType == 0)
     : this.extensionData.filter(res => res.keyType == 0);
   let queueData =
    this.concatenateData && this.groupData
     ? this.extensionDataConsolidated.filter(res => res.keyType == 4)
     : this.extensionData.filter(res => res.keyType == 4);
   let extensionDataSorted = [];
   let queueDataSorted = [];
   let fullData = [];

   extensionDataSorted = extensionData.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "answered_percentage") {
     if (a["answered"] / a["calls"] < b["answered"] / b["calls"])
      return -1 * modifier;
     if (a["answered"] / a["calls"] > b["answered"] / b["calls"])
      return 1 * modifier;
    } else if (toBeSorted === "duration_total_avg") {
     if (
      a["talking_duration"] / a["answered"] <
      b["talking_duration"] / b["answered"]
     )
      return -1 * modifier;
     if (
      a["talking_duration"] / a["answered"] >
      b["talking_duration"] / b["answered"]
     )
      return 1 * modifier;
     if (a["answered"] == 0 || b["answered"] == 0) {
      if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
      if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "duration_total_total") {
     if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
     if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
    } else if (toBeSorted === "waiting_total_avg") {
     if (
      a["waiting_duration"] / a["calls"] <
      b["waiting_duration"] / b["calls"]
     )
      return -1 * modifier;
     if (
      a["waiting_duration"] / a["calls"] >
      b["waiting_duration"] / b["calls"]
     )
      return 1 * modifier;
     if (a["calls"] == 0 || b["calls"] == 0) {
      if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
      if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "waiting_total_total") {
     if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
     if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });

   queueDataSorted = queueData.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "answered_percentage") {
     if (a["answered"] / a["calls"] < b["answered"] / b["calls"])
      return -1 * modifier;
     if (a["answered"] / a["calls"] > b["answered"] / b["calls"])
      return 1 * modifier;
    } else if (toBeSorted === "duration_total_avg") {
     if (
      a["talking_duration"] / a["answered"] <
      b["talking_duration"] / b["answered"]
     )
      return -1 * modifier;
     if (
      a["talking_duration"] / a["answered"] >
      b["talking_duration"] / b["answered"]
     )
      return 1 * modifier;
     if (a["answered"] == 0 || b["answered"] == 0) {
      if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
      if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "duration_total_total") {
     if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
     if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
    } else if (toBeSorted === "waiting_total_avg") {
     if (
      a["waiting_duration"] / a["calls"] <
      b["waiting_duration"] / b["calls"]
     )
      return -1 * modifier;
     if (
      a["waiting_duration"] / a["calls"] >
      b["waiting_duration"] / b["calls"]
     )
      return 1 * modifier;
     if (a["calls"] == 0 || b["calls"] == 0) {
      if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
      if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "waiting_total_total") {
     if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
     if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });
   fullData = [...queueDataSorted, ...extensionDataSorted];
   return fullData;
  },
  sortedDataQueue() {
   let queueData = this.concatenateData
    ? this.queueDataConsolidated
    : this.queueData;
   return queueData.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "answered_percentage") {
     if (a["answered"] / a["calls"] < b["answered"] / b["calls"])
      return -1 * modifier;
     if (a["answered"] / a["calls"] > b["answered"] / b["calls"])
      return 1 * modifier;
    } else if (toBeSorted === "duration_total_avg") {
     if (
      a["talking_duration"] / a["answered"] <
      b["talking_duration"] / b["answered"]
     )
      return -1 * modifier;
     if (
      a["talking_duration"] / a["answered"] >
      b["talking_duration"] / b["answered"]
     )
      return 1 * modifier;
     if (a["answered"] == 0 || b["answered"] == 0) {
      if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
      if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "duration_total_total") {
     if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
     if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
    } else if (toBeSorted === "waiting_total_avg") {
     if (
      a["waiting_duration"] / a["calls"] <
      b["waiting_duration"] / b["calls"]
     )
      return -1 * modifier;
     if (
      a["waiting_duration"] / a["calls"] >
      b["waiting_duration"] / b["calls"]
     )
      return 1 * modifier;
     if (a["calls"] == 0 || b["calls"] == 0) {
      if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
      if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "waiting_total_total") {
     if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
     if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  parseTimeRange(time) {
   if (time && time.length > 0) {
    const timeStart = time[0].slice(0, 2) + ":" + time[0].slice(2);
    const timeEnd = time[1].slice(0, 2) + ":" + time[1].slice(2);
    const finalTime = timeStart + " - " + timeEnd;
    return String(finalTime);
   }
  },
  formatTime(time) {
   let formatedTime = time.slice(0, 2) + ":" + time.slice(-2);
   return formatedTime;
  },
  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options)
    .format(month)
    .slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
  async getPbxMap() {
   if (this.hostName && this.hostName !== "null") {
    try {
     const res = await axios.get(
      `${localStorage.getItem("apiUrl")}/api/v1.2/${this.hostName}/map`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
       },
      }
     );
     this.$store.dispatch("pbxMap", res.data);
     localStorage.setItem("pbxMap", JSON.stringify(res.data));

     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  async getCallerNumbers() {
   this.queueData = [];
   this.extensionData = [];
   this.didNumbers = [];
   this.$emit("setLoading", true);

   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/dids-list?start=${this.startDate}&end=${this.endDate}`;

   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    let map =
     this.pbxMap && this.pbxMap.dids && this.pbxMap.dids.length > 0
      ? this.pbxMap.dids
      : this.getPbxMap().dids;
    let data = res.data;
    for (let index = 0; index < map.length; index++) {
     const element = map[index];
     data.forEach(res => {
      if (element.includes(res) && this.didNumbers.indexOf(element) < 0) {
       this.didNumbers.push(element);
      }
     });
    }
   } catch (error) {
    this.errorHandling(error);
   }
   this.$emit("setLoading", false);
  },
 },
 watch: {
  selectedtime: function (val) {
   let flag = false;
   if (this.concatenateData) {
    flag = true;
    this.concatenateData = false;
   }
   this.separateQueueExtensionData();
   if (flag) this.concatenateData = true;
  },
  period: function (val) {
   //  this.separateQueueExtensionData();
   //    this.getCallerNumbers();
  },
  groupData: function (val) {
   let grouped = "grouped";
   this.separateQueueExtensionData(grouped);
  },
  searchQueue: function (val) {
   if (val && val.length <= 0) {
    this.searchQueue = null;
   }
  },
  urlType: function (val) {
   this.$store.dispatch("pbxElements", null);
  },
  concatenateData: function (val) {
   if (val) this.groupData = true;
  },
 },
 mounted() {
  //   this.getPbxMap();
  //   if (!this.period && !this.pbxPeriodSelected) {
  //    this.period = this.currentMonthAndYearForPbx();
  //   }
  //   if (this.pbxPeriodSelected) this.period = this.pbxPeriodSelected;
  //   this.currentMonthAndYearForPbx();
  // if (this.pbxElements) {
  //  this.selectedElement = this.pbxElements;
  //  if (this.pbxElementType !== this.urlType) this.$store.dispatch("pbxElements", null);
  // }
  if (this.pbx3cxTableConfig) {
   this.timeRange = this.pbx3cxTableConfig?.following_interval;
   this.timeGap = this.pbx3cxTableConfig?.initial_interval;
   this.numberOfColumns = this.pbx3cxTableConfig?.count_interval;
  }

  if (this.pbxElements) {
   this.selectedElement = this.pbxElements;

   if (this.pbxElementType !== this.urlType)
    this.$store.dispatch("pbxElements", null);
  }
 },
 computed: {
  filteredQueueData() {
   return this.sortedDataQueue().filter(data => {
    if (this.searchQueue) {
     let flag = false;
     this.searchQueue.forEach(queue => {
      if (queue.includes(data.queue) && queue.includes(data.queueName)) {
       flag = true;
      }
     });
     return flag;
    } else {
     return data;
    }
   });
  },
  filteredExtensionData() {
   return this.sortedDataExtension().filter(data => {
    if (this.searchQueue && !this.searchExtension) {
     let flag = false;
     this.searchQueue.forEach(queue => {
      if (queue.includes(data.queue) && queue.includes(data.queueName)) {
       flag = true;
      }
     });
     return flag;
    } else if (!this.searchQueue && this.searchExtension) {
     let flag = false;
     this.searchExtension.forEach(element => {
      if (
       element.includes(data.element) &&
       element.includes(data.elementName)
      ) {
       flag = true;
      }
     });
     return flag;
    } else if (this.searchQueue && this.searchExtension) {
     let flag = false;
     let flagQueue = false;
     let flagExtension = false;
     this.searchExtension.forEach(element => {
      if (
       element.includes(data.element) &&
       element.includes(data.elementName)
      ) {
       flagExtension = true;
      }
     });
     this.searchQueue.forEach(queue => {
      if (queue.includes(data.queue) && queue.includes(data.queueName)) {
       flagQueue = true;
      }
     });
     if (flagExtension && flagQueue) {
      flag = true;
     }
     return flag;
    } else {
     return data;
    }
   });
  },
  sortedExtensionData() {
   let firstSort = this.extensionData.sort((a, b) => {
    if (a.keyType == 4) {
     return -1;
    }
   });

   return firstSort.sort((a, b) => {
    if (a.keyType == 4) {
     return parseInt(a.date) - parseInt(b.date);
    }
   });
  },
  ...mapGetters([
   "hostName",
   "hostTimeZone",
   "pbxPeriodSelected",
   "pbxElements",
   "pbxMap",
   "pbxElementType",
   "pbx3cxTableConfig",
  ]),
 },
};
</script>

<style>
.switch-group {
 position: absolute;
 right: 1.3rem;
 top: 23.5rem;
}
.switch-group-no-info {
 position: absolute;
 right: 1.3rem;
 top: 15.5rem;
}
</style>
